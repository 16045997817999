body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.header-image {
  width: 100%;
  opacity: 0.4;
  min-height: 350px
}

.header-image-container {
  position: relative;
  text-align: center;
  color: white;
}

.header-jumbotron {
  padding: 0;
}

.header-text {
  color: black;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.cards-section {
  text-align: center;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.sbham-card {
  max-width: 400px;
  display: inline-block;
}

.body-section {
  margin: 2em;
}

.video-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}

.video-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
